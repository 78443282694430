<template>
  <div id="paginaLogin">
    <el-card id="loginCard" v-loading="cargando">
      <div id="loginBody">
        <transition name="fade-in-slow">
          <div v-show="mostrarLogo" style="display: flex">
            <img
              src="../../assets/logoestrella.png"
              height="35px"
              style="margin: auto"
            />
          </div>
        </transition>
        <el-form id="loginForm" :model="form" :rules="rules" ref="form">
          <el-form-item prop="username">
            <el-input
              ref="username"
              class="input-login"
              placeholder="Usuario"
              v-model="form.username"
              :autofocus="true"
              @keyup.enter.native="login"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              class="input-login"
              placeholder="Contraseña"
              v-model="form.password"
              type="password"
              @keyup.enter.native="login"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="display: flex">
          <el-button
            id="botonIngresar"
            type="primary"
            @click="login"
            style="margin: auto"
            round
          >
            <span>Ingresar</span>
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<style scoped>
#loginCard {
  background: #000000aa !important;
  max-width: 300px;
  margin: auto;
}

#paginaLogin {
  height: calc(100vh - 20px);
  display: flex;
}

#loginBody {
  display: grid;
  height: 300px;
  padding: 30px;
}

#loginForm {
  padding-top: 20px;
  align-self: center;
}

#botonIngresar {
  align-self: end;
  width: 100%;
  font-size: 20px;
}
</style>

<style>
.input-login > input {
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0px;
  border-bottom: 2px solid #d0d0da65 !important;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  color: rgb(236, 236, 236);
}
</style>

<script>
export default {
  name: "login",
  data() {
    return {
      form: {
        username: null,
        password: null,
      },

      mostrarLogo: false,
      cargando: false,
    };
  },
  computed: {
    rules() {
      let rules = {
        username: [
          {
            required: true,
            message: "Por favor introduzca un nombre de usuario.",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "Por favor introduzca la contraseña.",
            trigger: "change",
          },
        ],
      };

      return rules;
    },
  },
  created() {
    setTimeout(() => {
      this.mostrarLogo = true;
    }, 50);
  },
  methods: {
    login: function () {
      this.$refs.form.validate((respuesta, data) => {
        if (respuesta) {
          let params = {
            usuario: this.form.username,
            password: this.form.password,
            ios: 0,
            android: 0,
            web: 1,
          };

          this.cargando = true;

          this.$maca.api.post("/auth/login", params).then((respuesta) => {
            this.postRealizado(respuesta);
          });
        }
      });
    },

    postRealizado(respuesta) {
      this.cargando = false;

      if (respuesta.estado != 1) {
        if (respuesta.estado == -2) {
          this.$message({
            message: "El usuario no existe.",
            type: "error",
          });
        } else if (respuesta.estado == -3) {
          this.$message({
            message: "El usuario está bloqueado.",
            type: "error",
          });
        } else if (respuesta.estado == -4) {
          this.$message({
            message:
              "El usuario está bloqueado. Cantidad de intentos excedida, intente en 5 minutos.",
            type: "error",
          });
        } else if (respuesta.estado == -5) {
          this.$message({
            message: "Sin roles autorizados.",
            type: "error",
          });
        } else {
          this.$message({
            message: "La contraseña no es correcta.",
            type: "error",
          });
        }
        return;
      }

      let user = {
        token: respuesta.datos.token,
        roles: respuesta.datos.roles,
        permisos: respuesta.datos.permisos,
        usuario: respuesta.datos.usuario,
      };

      this.$store.commit("login", user);
      this.$router.push("/");
    },
  },
};
</script>
